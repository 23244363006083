import React, { useState } from "react";
import CountryPhoneInput from "antd-country-phone-input";
import axios from "axios";
import { Button, Form, Input, Alert } from "antd";

const InputForm = () => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [visible, setVisible] = useState(false);

  const onFinish = (values) => {
    values.phoneNumber =
      "+" + values.phoneNumber.code + values.phoneNumber.phone;
    axios({
      url: "https://employees-service.handsonsystems.com/askoll_drivers",
      method: "POST",
      data: values,
    })
      .then((res) => {
        setVisible(false);
        window.location =
          "https://veriff.handsonsystems.com" +
          "?firstName=" +
          values.firstName +
          "&lastName=" +
          values.lastName +
          "&idCardNumber=" +
          values.idCardNumber;
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setVisible(true);
      });
  };

  return (
    <Form
      form={form}
      name="inputForm"
      onFinish={onFinish}
      scrollToFirstError
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          {
            required: true,
            message: "Please input your first name!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          {
            required: true,
            message: "Please input your last name!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="idCardNumber"
        label="ID Card Number"
        rules={[
          {
            required: true,
            message: "Please input your ID card number!",
            whitespace: false,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not a valid e-mail!",
          },
          {
            required: true,
            message: "Please input your e-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        label="Phone Number"
        rules={[
          {
            required: true,
            message: "Please input your phone number!",
          },
        ]}
      >
        <CountryPhoneInput inline />
      </Form.Item>
      <Form.Item
        name="tagFriendlyName"
        label="Friendly Card ID"
        tooltip="The Friendly Card ID is the number on the provided card"
        rules={[
          {
            required: true,
            message: "Please input the friendly card ID!",
            // whitespace: false,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" block>
          Submit
        </Button>
      </Form.Item>
      {visible && <Alert message={errorMessage} type="error" showIcon />}
    </Form>
  );
};
export default InputForm;
