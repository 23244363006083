import { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import logo from './images/handsonLogo.png';
import './App.css';
import InputForm from './InputForm';

function App() {
  return (
    <ConfigProvider locale={en}>
      <div className="App">
      <h3 className='footer-text'>Driver Registration Form</h3>
        <InputForm />
        <div className='footer-text'>Developed by</div>
        <a href="https://www.handsonsystems.com/" target="_blank">
          <img className="handson-logo" src={logo} alt="HadsOn Logo" />  
        </a>
        </div>
      </ConfigProvider>
  );
}

export default App;
